<template>
  <div style="text-align: center;">
    <div class="next-event" style="text-align: start;">
      <img draggable="false" class="event-img" src="../assets/party/next-event.jpg">
      <div class="right">
        <h2>Eventname</h2>
        <div class="data">
          <div class="data-name">DATUM</div>
          <div class="data-value">27.12.2023</div>
        </div>
        <div class="data">
          <div class="data-name">Standort</div>
          <div class="data-value">Schmerzke Bunker</div>
        </div>
        <div class="data">
          <div class="data-name">Einlass</div>
          <div class="data-value">21:00 Uhr</div>
        </div>
      </div>
      <div class="show">
        <div class="data">
          <h3>Wer legt auf?</h3>
          <div class="data-value" style="margin-left: 2vh;">
            <ul style="font-size: 1.6vh;">
              <li>DJ Tommy (21.00-22.00)</li>
              <li>DJ Flo (21.00-22.00)</li>
              <li>DJ Corki (21.00-22.00)</li>
              <li>DJ Vinni (21.00-22.00)</li>
            </ul>
          </div>
        </div>
      </div>
    </div> 
    <iframe class="maps" style="margin-top: 10vh;border-radius: 1vh;margin-bottom: 5vh;" width="520" height="411" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=411&amp;hl=en&amp;q=Pr%C3%B6tzelweg%20Brandenburg+(Bunker%20in%20Schmerzke)&amp;t=k&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
  </div>
</template>