import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RulesView from '../views/RulesView.vue'
import AboutView from '../views/AboutView.vue'
import PicsView from '../views/PicsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/rules',
    name: 'Rules',
    component: RulesView
  },
  {
    path: '/aboutus',
    name: 'About Us',
    component: AboutView
  },
  {
    path: '/pics',
    name: 'Pics',
    component: PicsView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
