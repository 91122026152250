<template>
    <TopView />
    <HamburgerView />
    <PictureView />
</template>

<script>
import TopView from '@/components/TopView.vue';
import HamburgerView from '@/components/HamburgerView.vue';
import PictureView from '@/components/PictureView.vue';

export default {
    name: 'PicsView',
    components: {
        TopView,
        HamburgerView,
        PictureView
    }
}
</script>