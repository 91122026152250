<template>
    <div class="pics-wrapper">
        <h2 class="title">Neuste Fotos</h2>
        <p class="desc">Stand: 20.12.2023</p>
        <br>
        <div class="pics">
            <img draggable="false" src="../assets/party/big.jpg" class="big-left">
            <div class="right">
                <div class="p-top">
                    <img draggable="false" src="../assets/party/top-left.jpg" class="small-left">
                    <img draggable="false" src="../assets/party/top-right.jpg" class="small-right">
                </div>
                <img draggable="false" class="bottom" src="../assets/party/bottom.jpg">
            </div>
        </div>
    </div>
</template>