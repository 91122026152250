<template>
    <TopView />
    <HamburgerView />
    <div class="next-event" style="flex-direction: column;padding: 3vh;">
        <h1>Regeln</h1>
        <ol style="margin-left: 2vh;margin-top: 2vh;">
            <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Libero expedita harum ut deleniti, perspiciatis ducimus repellat repudiandae adipisci praesentium iste ex mollitia vitae. Iusto vitae earum odit deserunt illo maiores?</li>
            <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Libero expedita harum ut deleniti, perspiciatis ducimus repellat repudiandae adipisci praesentium iste ex mollitia vitae. Iusto vitae earum odit deserunt illo maiores?</li>
            <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Libero expedita harum ut deleniti, perspiciatis ducimus repellat repudiandae adipisci praesentium iste ex mollitia vitae. Iusto vitae earum odit deserunt illo maiores?</li>
            <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Libero expedita harum ut deleniti, perspiciatis ducimus repellat repudiandae adipisci praesentium iste ex mollitia vitae. Iusto vitae earum odit deserunt illo maiores?</li>
        </ol>
    </div>
</template>

<script>
import TopView from '@/components/TopView.vue';
import HamburgerView from '@/components/HamburgerView.vue';

export default {
    name: 'RulesView',
    components: {
        TopView,
        HamburgerView
    }
}
</script>