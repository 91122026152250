<template>
  <TopView />
  <HamburgerView />
  <EventView />
</template>

<script>
import TopView from '@/components/TopView.vue';
import EventView from '@/components/EventView.vue';
import FooterView from '@/components/FooterView.vue';
import HamburgerView from '@/components/HamburgerView.vue';

export default {
  name: 'HomeView',
  components: {
    TopView,
    EventView,
    FooterView,
    HamburgerView
  }
}
</script>