<template>
    <TopView />
    <HamburgerView />
</template>

<script>
import TopView from '@/components/TopView.vue';
import HamburgerView from '@/components/HamburgerView.vue';

export default {
    name: 'AboutView',
    components: {
        TopView,
        HamburgerView
    }
}
</script>